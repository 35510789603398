import React from "react";
import {withRouter} from "react-router-dom";
import TranslationItems from "../components/pages/result/TranslationItems";
import AddFilesBtn from "../components/pages/AddFilesBtn";
import {FileDrop} from "react-file-drop";
import uploadFiles from "../utils/uploadFiles";
const ResultPage=({history,translations,deleteTranslationItem,addFiles})=>{

    return <div className="upload-content__wrapper">
        <h3>Upload content</h3>

        <FileDrop onDrop={(files, e) => uploadFiles({files, e,history,addFiles})}>
            <div className="upload-content__files">
            <div className="upload-content__files-list">
               <TranslationItems translations={translations} deleteTranslationItem={deleteTranslationItem}/>
            </div>
                <div className="upload-content__files-upload__button">
                    <AddFilesBtn history={history} text={"Add"} className={'btn-add btn-add_second'}/>
                </div>

            </div>
        </FileDrop>
    </div>
};
export default withRouter(ResultPage);

