const splitToSegments = require('./splitToSegments').default;
const getRepetitionsFromSegments = require('./getRepetitionsFromSegments').default;

/**
 * @param str
 * @returns {{duplicateWords: *, duplicateSegments: *, newSegments: *, newWordsText: *, newWords: *, duplicatesWordsText: *, segments: *}}
 */
export const findDuplicateWords = str => {


    const segments =splitToSegments(str);


    const {newSentences,repSentences} = getRepetitionsFromSegments(segments);


    return {
        duplicatesWordsText:repSentences.join(" "),
        newWordsText:newSentences.join(" "),
        duplicateWords:repSentences.join(" ").length,
        newWords:newSentences.join(" ").length,
        segments:segments.length,
        duplicateSegments:repSentences.length,
        newSegments:newSentences.length,
    };
};



