import React from "react";
import ReactDOM from "react-dom";
import { Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { history } from "./utils/history";
import store from "./redux-store";
import "./assets/styles/app.scss";
import App from "./App";
import Login from "./containers/Login";
import PrivateRoute from "./PrivateRoute";

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route component={Login} path={"/login"} key={2} exact={true} />
        <PrivateRoute component={App} path={"/*"} key={1} exact={false} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
