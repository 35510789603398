import { connect } from "react-redux";
import ResultPage from "../pages/ResultPage";
import {addFiles, deleteTranslationItem} from "../redux-store/actions";

const mapStateToProps = ({translation:{items}}) => ({
    translations:items
});

const mapDispatchToProps = (dispatch) => ({
    deleteTranslationItem: (data) => dispatch(deleteTranslationItem(data)),
    addFiles: (data) => dispatch(addFiles(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultPage);
