import { SET_CURRENT_USER, SET_PREVIOUS_URL, SET_TOKEN } from "./types";

export const setToken = (token) => {
  return {
    type: SET_TOKEN,
    payload: token,
  };
};
export const setUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};
export const setPreviousUrl = (decoded) => {
  return {
    type: SET_PREVIOUS_URL,
    payload: decoded,
  };
};
