import React, { useState, useRef } from "react";
import useOutsideClick from "../../utils/useOutsideClick";
import { addFiles } from "../../redux-store/actions";
import uploadFiles from "../../utils/uploadFiles";
import { connect } from "react-redux";

const AddFilesBtn = ({
  history,
  addFiles,
  text = "Add files",
  className = "btn-add",
}) => {
  const [btnOpen, setBtnOpen] = useState(false);

  const ref = useRef();

  useOutsideClick(ref, () => {
    setBtnOpen(false);
  });

  return (
    <div className={`${className} ${btnOpen ? "btn-add_opened" : ""} `}>
      <div className="btn-add__header">
        <div className="btn-add__input btn-add__input_add">
          <label htmlFor="file-upload" className="btn-add__input-label">
            {text}
          </label>
          <input
            type="file"
            id="file-upload"
            name="myfile"
            multiple={true}
            onChange={(e) => {
              setBtnOpen(false);
              uploadFiles({
                files: e.target.files,
                e,
                history,
                addFiles,
              });
            }}
            style={{
              visibility: "hidden",
              position: "absolute",
              zIndex: "-10",
            }}
          />
        </div>
        <div onClick={() => setBtnOpen(!btnOpen)} className="btn-add__icon" />
      </div>
      <div className="btn-add__dropdown">
        <ul className="btn-add__list">
          <li className="btn-add__item">
            <div className="btn-add__input">
              <label htmlFor="file-upload-2" className="btn-add__input-label">
                From computer
              </label>
              <input
                type="file"
                id="file-upload-2"
                name="myfile"
                multiple={true}
                onChange={(e) => {
                  setBtnOpen(false);
                  uploadFiles({
                    files: e.target.files,
                    e,
                    history,
                    addFiles,
                  });
                }}
                style={{
                  visibility: "hidden",
                  position: "absolute",
                  zIndex: "-10",
                }}
              />
            </div>
          </li>
          <li className="btn-add__item">
            <a
              href="/custom-input"
              onClick={(e) => {
                e.preventDefault();
                history.push("/custom-input");
              }}
              className="btn-add__link btn-add__link_text"
            >
              Create text
            </a>
          </li>
          <li className="btn-add__item">
            <button className="btn-add__link btn-add__link_url">By URL</button>
          </li>
        </ul>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  addFiles: (data) => dispatch(addFiles(data)),
});

export default connect(null, mapDispatchToProps)(AddFilesBtn);
