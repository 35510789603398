import React, {useEffect} from 'react';
import {withRouter} from "react-router-dom";
import TranslationItem from "./TranslationItem";

const TranslationItems=({translations,deleteTranslationItem,history})=>{

    useEffect(()=>{
        if(!translations || !Object.keys(translations).length){
            history.push('/')
        }

    });

    useEffect(()=>{
        if(!translations || !Object.keys(translations).length){
            history.push('/')
        }
    });

    const editItem=(id)=>{
        history.push('/custom-input',{id:id,item:translations[id]})
    };

    const deleteItem=(id)=>{
        console.log('id',id);
        deleteTranslationItem(id);
    };



    return <>
        {!!(translations && Object.keys(translations).length) && Object.keys(translations).map((key,index)=>
            <TranslationItem editItem={editItem} deleteItem={deleteItem} item={translations[key]} id={key} key={key}/>
        )}

    </>
};
export default withRouter(TranslationItems)
