/**
 * @param str
 * @returns {[]}
 */
const splitToSegments = (str) => {
  let regex = /([.,?!;:(){}"'-/\n])/u;
  // let regex = /\n+/;

  const segments = str.split(regex);
  // .map(item=>item.trim())
  // .filter(item=>item);

  // const cdlrSegments = cldrSegmentation.sentenceSplit(str,teeSuppression);

  // console.log('cdlrSegments',cdlrSegments);

  let newSegments = [];

  for (let segment of segments) {
    if (segment === "\n" || !segment.trim()) continue;

    if (segment.match(regex) && segment.match(regex)[0]) {
      if (newSegments.length > 0) {
        newSegments[newSegments.length - 1] += segment;
      }
    } else {
      newSegments.push(segment);
    }
  }
  return newSegments;
};

export default splitToSegments;
