import IndexPage from "./containers/IndexPage";
import CustomInputPage from "./containers/CustomInputPage";
import ResultPage from "./containers/ResultPage";

export const routes = [
  {
    component: CustomInputPage,
    path: "/custom-input",
  },
  {
    component: ResultPage,
    path: "/result",
  },
  {
    component: IndexPage,
    path: "/",
  },
];
