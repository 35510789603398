import axios from "axios";
import {
  setPreviousUrl,
  setToken,
  setUser,
} from "../redux-store/actions/authentication";
import { deleteCookie } from "../utils/cookie";
import { jwtDecode } from "jwt-decode";

const apiUrl = process.env.REACT_APP_KOT_CLOUD_API_URL;

export const login = async (formData, dispatch) => {
  const { data } = await axios.post(apiUrl + "/login", formData);

  const token = data.token;

  setAuthToken(token);
  dispatch(setToken(token));
  localStorage.setItem("userToken", token);
};

export const logoutUser = (props, setPrevious, dispatch) => {
  localStorage.removeItem("userToken");
  setAuthToken(false);
  dispatch(setUser());
  dispatch(setToken(null));

  if (window.location.pathname.indexOf("/login") < 0 && setPrevious) {
    dispatch(setPreviousUrl(window.location.pathname));
  }

  if (props?.history) {
    props.history.push("/login");
  } else {
    window.location.href = "/login";
  }
};

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    deleteCookie("Authorization");
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const setUserFromLocalStorage = (props, dispatch) => {
  if (typeof Storage !== "undefined" && localStorage.userToken) {
    setAuthToken(localStorage.userToken);
    const decoded = jwtDecode(localStorage.userToken);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      logoutUser(props, true, dispatch);
      return false;
    } else {
      return decoded;
    }
  }
  return false;
};

const authSagas = [];

export default authSagas;
