import { connect } from "react-redux";
import CustomInputPage from "../pages/CustomInputPage";
import {addTranslationItem, setCrossParams, updateTranslationItem} from "../redux-store/actions";


const mapStateToProps = ({translation:{dataFile}}) => ({
    dataFile:dataFile
});

const mapDispatchToProps = (dispatch) => ({
    addTranslationItem: (data) => dispatch(addTranslationItem(data)),
    updateTranslationItem: (data) => dispatch(updateTranslationItem(data)),
    setCrossParams: (data) => dispatch(setCrossParams(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomInputPage);
