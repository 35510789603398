/**
 * @param segments
 * @returns {{repSentences: *, newSentences: *}}
 */
const getRepetitionsFromSegments=(segments)=>{


    let repSentences = [];
    let newSentences = [];
    for(let string of segments){
        let sentense = string
            .toLowerCase()
            .replace('\n', " ")
            // .replace(/[.,?!;()"'-]/g, " ")
            // .replace("  ", " ")
            // .replace(/\s+/g, " ")
            .trim();
        if(sentense){
            if(!newSentences.includes(sentense)){
                newSentences.push(sentense);
            }else{
                repSentences.push(sentense);
            }
        }
    }

    return {
        repSentences,newSentences
    }
};
export default getRepetitionsFromSegments;
