import { connect } from "react-redux";
import Login from "../pages/Login";

const mapStateToProps = ({ errorLoading: { isLoading } }) => ({
  isLoading,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
