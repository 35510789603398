import React from "react";
import {connect} from "react-redux";
import CopyItem from "./copyItem";
const Summary = ({
                     translations,
                     words,
                     characters,
                     allCharacters,
                     allDuplicateCharacters,
                     allNewCharacters,
                     duplicateCharacters,
                     newCharacters,
                     newWords,
                     duplicateWords,
                     crossCharacters,
                     allCrossCharacters,
                     crossWords,
                     segments,
                     duplicateSegments,
                     newSegments,
                     crossSegments
}) => {


    return (
        <div className="summary">
            <h2>Summary</h2>
            {(translations && Object.keys(translations).length)
                ?
                <ul>
                    <li>
                        <span></span>
                        <span>Segments</span>
                        {/*<span>Characters (incl spaces)</span>*/}
                        <span>Characters (without spaces)</span>
                        <span>Words</span>
                    </li>
                    <li>
                        <span>Repetitions</span>
                        <CopyItem>{duplicateSegments}</CopyItem>
                        {/*<span>{allDuplicateCharacters}</span>*/}
                        <CopyItem>{duplicateCharacters}</CopyItem>
                        <CopyItem>{duplicateWords}</CopyItem>
                    </li>
                    <li>
                        <span>Cross-file rep.</span>
                        <CopyItem>{crossSegments}</CopyItem>
                        <CopyItem>{crossCharacters}</CopyItem>
                        <CopyItem>{crossWords}</CopyItem>
                    </li>
                    <li>
                        <span>New</span>
                        <CopyItem>{newSegments}</CopyItem>
                        {/*<span>{allNewCharacters}</span>*/}
                        <CopyItem>{newCharacters}</CopyItem>
                        <CopyItem>{newWords}</CopyItem>
                    </li>
                    <li className="summary-total">
                        <span>Total</span>
                        <CopyItem>{segments}</CopyItem>
                        {/*<span>{allCharacters}</span>*/}
                        <CopyItem>{characters}</CopyItem>
                        <CopyItem>{words}</CopyItem>
                    </li>
                </ul>
                :
                <p>Nothing to show yet...</p>

            }
        </div>
    );
};

const mapStateToProps = ({translation:{
    words,
    characters,
    allCharacters,
    allDuplicateCharacters,
    allNewCharacters,
    duplicateCharacters,
    newCharacters,
    newWords,
    duplicateWords,
    crossCharacters,
    allCrossCharacters,
    crossWords,
    segments,
    duplicateSegments,
    newSegments,
    crossSegments,
    items,

}}) => ({
    words,
    characters,
    allCharacters,
    allDuplicateCharacters,
    allNewCharacters,
    duplicateCharacters,
    newCharacters,
    newWords,
    duplicateWords,
    crossCharacters,
    allCrossCharacters,
    crossWords,
    segments,
    duplicateSegments,
    newSegments,
    crossSegments,
    translations:items,
});


export default connect(mapStateToProps)(Summary);

