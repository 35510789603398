import React from "react";

const MainLayout=({children})=>{
    return <div className="upload-content">
                <div className="container">
                    {children}
                </div>
            </div>
};
export default MainLayout
