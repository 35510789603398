import { connect } from "react-redux";
import IndexPage from "../pages/IndexPage";
import {addFiles, setOcrEngine} from "../redux-store/actions";

const mapStateToProps = ({translation:{ocrEngine}}) => ({
    ocrEngine:ocrEngine
});
const mapDispatchToProps = (dispatch) => ({
    addFiles: (data) => dispatch(addFiles(data)),
    setOcrEngine: (data) => dispatch(setOcrEngine(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
