import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { routes } from "./routes";
import MainLayout from "./components/MainLayout";
import Summary from "./components/summary/Summary";
import { socket } from "./context/socket";
import { setDataFile, updateTranslationItem } from "./redux-store/actions";
import { connect } from "react-redux";

import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";

const createRoutes = (routes) => {
  return routes.map((route) => (
    <Route
      key={route.path || route.name}
      path={route.path}
      component={route.component}
      exact={true}
    />
  ));
};

const App = ({ translations, updateTranslation, setFileData }) => {
  const [currentTranslations, setCurrentTranslations] = useState(translations);

  const updatePageNumber = useEffect((jsonObject) => {
    if (
      jsonObject?.id &&
      jsonObject.pages &&
      currentTranslations &&
      currentTranslations[jsonObject.id]
    ) {
      let newTranslation = { ...translations[jsonObject.id] };
      newTranslation.pages = jsonObject.pages;
      newTranslation.page = jsonObject.page || 0;
      newTranslation.timePerPage = jsonObject.timePerPage;
      newTranslation.uploadProgress = 100;

      if (
        currentTranslations &&
        newTranslation &&
        currentTranslations[jsonObject.id] &&
        currentTranslations[jsonObject.id].page !== newTranslation.page
      ) {
        updateTranslation(newTranslation);
      }
    }
  });

  const setDataFilePath = useEffect((path) => {
    console.log("path", path);
    setFileData(path);
  });

  useEffect(() => {
    /**
     * Detects pages
     */

    socket.off("dataFile", setDataFilePath).on("dataFile", setDataFilePath);
    return () => {
      socket.off("dataFile", setDataFilePath);
    };
  }, [setDataFilePath]);

  useEffect(() => {
    /**
     * Detects pages
     */

    socket
      .off("detect-status", updatePageNumber)
      .on("detect-status", updatePageNumber);
    return () => {
      socket.off("detect-status", updatePageNumber);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(translations), updatePageNumber]);

  useEffect(() => {
    setCurrentTranslations(translations);
  }, [translations]);

  return (
    <div className="app">
      <MainLayout>
        <Switch>{createRoutes(routes)}</Switch>
        <Summary />
        <NotificationContainer />
      </MainLayout>
    </div>
  );
};

const mapStateToProps = ({ translation: { items } }) => ({
  translations: items,
});
const mapDispatchToProps = (dispatch) => ({
  updateTranslation: (data) => dispatch(updateTranslationItem(data)),
  setFileData: (data) => dispatch(setDataFile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
