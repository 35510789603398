
import React from 'react';
import {NotificationManager} from 'react-notifications';

const CopyItem=({children})=>{


    const copy=()=>{
        navigator.clipboard.writeText(children);
        NotificationManager.info('Copied!');

    };


    return <>
                <span onClick={copy}>{children}</span>
            </>
};
export default CopyItem;
