
const uploadFiles = ({files, e,history,addFiles}) => {
    e.preventDefault();
    e.stopPropagation();
    const newFiles = [];
    for (let i = 0; i < files.length; i++) {
        newFiles.push({ data: files[i], name: files[i].name });
    }
    addFiles(newFiles);
    history.push('result')
};
export default uploadFiles;
