import { useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { setPreviousUrl } from "./redux-store/actions/authentication";
import { connect, useDispatch } from "react-redux";
import { setUserFromLocalStorage } from "./services/authentication";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const auth = setUserFromLocalStorage({ history }, dispatch);
  console.log("auth", { auth });
  const [authorized] = useState(auth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authorized) {
          setPreviousUrl(window.location.pathname);
          history.push("/login");
          return;
        }
        return authorized ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  setPreviousUrl: (url) => dispatch(setPreviousUrl(url)),
  setUserFromLocalStorage: (history) =>
    setUserFromLocalStorage(history, dispatch),
});

export default connect(null, mapDispatchToProps)(PrivateRoute);
