import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_URL,
  withCredentials: true,
  // token is set in the authentication service
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.response.use(
  (res) => res.data,
  async (e) => {
    const errorMessage = e.message || e?.response?.data;
    return Promise.reject(new Error(errorMessage));
  }
);
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("userToken");
  if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
});

export const sendCustomText = async ({ data, dataFile }) => {
  try {
    let url = `/set-item-text`;

    if (dataFile) {
      url += `?dataFile=${dataFile}`;
    }

    return await api.post(
      url,
      { data: data },
      {
        timeout: 0,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    return e.message;
  }
};
export const deleteCustomText = async ({ id, dataFile }) => {
  try {
    let url = `/delete-item-text/${id}`;

    if (dataFile) {
      url += `?dataFile=${dataFile}`;
    }

    return await api.delete(url, {
      timeout: 0,
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    return e.message;
  }
};
export const uploadFiles = async ({
  data,
  filesInfo,
  ocrEngine,
  dataFile,
  setUploadProgress,
}) => {
  let url = `/upload`;
  if (ocrEngine) {
    url += `?ocrEngine=${ocrEngine}`;
  }
  if (dataFile) {
    url +=
      url.indexOf("?") !== -1
        ? `&dataFile=${dataFile}`
        : `?dataFile=${dataFile}`;
  }
  return await api.post(url, data, {
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setUploadProgress(filesInfo, percentCompleted);
    },
    timeout: 0,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
