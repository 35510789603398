import React, {useState} from "react";

const ManualIcon = ()=>{

    const [show, setShow] = useState(false)

    return  <div className="upload-manual" onMouseEnter={()=>setShow(true)} onMouseLeave={()=>setShow(false)}>
                <button className="btn btn-preview-toggle">
                    <strong className="btn-preview-toggle__text">MR</strong>
                </button>

                <div className={`upload-manual-review ${show ? 'upload-manual-review-visible' : ''}`} >
                    <b>Size for manual review</b>
                    <p>Our counter can’t estimate this file size, but no worries, we will review it manually, keep
                        filling.</p>
                </div>
            </div>
};
export default ManualIcon
